import React from 'react'

import './externalNavbar.css'

const ExternalNavbar = () => {
  return (
    <div className='externalNavbar'>
      <div className='externalNavbar__content'>
        <p className='externalNavbar__title'>Граффити Пермь</p>
        <nav className='externalNavbar__nav-list'>
          <a href='https://t.me/+2xqi2zCWfUY4YmY0' target='_blank' rel='noopener noreferrer' className='externalNavbar__nav-list__item'>
            Telegram
          </a>
          <div className='externalNavbar__nav-list__separator'></div>
          <a href='https://vk.com/public219627176' target='_blank' rel='noopener noreferrer' className='externalNavbar__nav-list__item'>
            VK
          </a>
          <div className='externalNavbar__nav-list__separator'></div>
          <a href='mailto: graffiti.perm@yandex.ru' target='_blank' rel='noopener noreferrer' className='externalNavbar__nav-list__item'>
            Поддержка
          </a>
          {/*<div className='externalNavbar__nav-list__separator'></div>*/}
          {/*<a href='https://disk.yandex.ru/i/HfLDW_vslz5V7g' target='_blank' rel='noopener noreferrer' className='externalNavbar__nav-list__item'>*/}
          {/*  Monument Art*/}
          {/*</a>*/}
        </nav>
      </div>
    </div>
  )
}

export default ExternalNavbar
