import React, { useState, useEffect } from 'react'
import './interactiveMap.css'
// import red_mark from '../../assets/red_mark.svg'
import { CustomNavigation, Button, MapInfo } from '../../components'
import locations from '../../data/locations.json'
import { Link } from 'react-router-dom'
import { YMaps, Map, Placemark, ZoomControl, FullscreenControl } from '@pbe/react-yandex-maps'

const InteractiveMap = (props) => {
  const API_TOKEN = '182f7126-c1dd-40bd-8462-24676da5d1d9'

  const defaultState = { center: [57.990239, 56.252802], zoom: 12, controls: [] }

  const [points, setPoints] = useState(locations)

  const [filter, setFilter] = useState('all')

  const [selectedMark, setSelectedMark] = useState(null)

  const [isActive, setIsActive] = useState(1)

  const [isSwiped, setIsSwiped] = useState(false)

  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)

  const minSwipeDistance = 50

  const handleFilterChange = (filter) => {
    setFilter(filter)
  }

  const filteredPoints = filter === 'all' ? points : points.filter((point) => point.status === filter)

  const handleMarkClick = (id) => {
    const info = filteredPoints.find((info) => info.id === id)
    selectedMark === info ? setSelectedMark(null) : setSelectedMark(info)
  }

  const onTouchStart = (e) => {
    setTouchEnd(null)
    setTouchStart(e.targetTouches[0].clientY)
    console.log(e.targetTouches[0])
  }

  const onTouchMove = (e) => {
    e.preventDefault()
    setTouchEnd(e.targetTouches[0].clientY)
  }

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isUpSwipe = distance > minSwipeDistance
    const isDownSwipe = distance < -minSwipeDistance
    if (isUpSwipe || isDownSwipe) {
      isUpSwipe ? setIsSwiped(true) : setIsSwiped(false)
    }
  }

  const openTab = (e) => setIsActive(+e.target.dataset.index)

  let className = 'map-container'

  if (props.isNone) {
    className += ' none_class'
  }
  if (props.isPad) {
    className += ' section__padding'
  }

  return (
    <>
      <div className={className} id='map'>
        <CustomNavigation title='Карта проектов' isDisabled isMapPage />
        <div className='filterBar'>
          <div className='filterBar__tabs'>
            <button
              className={`${1 === isActive ? 'active' : 'filterBar__tabs-item '}`}
              onClick={(e) => {
                openTab(e)
                handleFilterChange('all')
              }}
              data-index={1}
            >
              Все
            </button>
            <button
              className={`${2 === isActive ? 'active' : 'filterBar__tabs-item '}`}
              onClick={(e) => {
                openTab(e)
                handleFilterChange('Свободная локация')
              }}
              data-index={2}
            >
              Свободная&nbsp;локация
            </button>
            <button
              className={`${3 === isActive ? 'active' : 'filterBar__tabs-item '}`}
              onClick={(e) => {
                openTab(e)
                handleFilterChange('Реализованный проект')
              }}
              data-index={3}
            >
              Реализованный&nbsp;проект
            </button>
          </div>
        </div>

        <div className='info-container'>
          <YMaps query={{ apikey: API_TOKEN }}>
            <Map defaultState={defaultState} className='yandexMap'>
              <ZoomControl
                options={{
                  position: { top: 108, right: 10, bottom: 'auto', left: 'auto' },
                }}
              />
              <FullscreenControl />
              {filteredPoints.map((item) => (
                <Placemark geometry={item.coords} options={{}} onClick={() => handleMarkClick(item.id)} key={item.id} />
              ))}

              {selectedMark && <MapInfo title={selectedMark.title} status={selectedMark.status} address={selectedMark.address} images={selectedMark.images} info={selectedMark.info} team={selectedMark.team} />}
            </Map>
          </YMaps>
        </div>
      </div>

      {props.isMobile && (
        <div className='map-container_m section__padding'>
          <div className='map-container_m-bg'>
            <h3 className='map-container_m__title'>Карта проектов</h3>
            <Link to='/mobile-map'>
              <Button title='Открыть' isWhite link='/mobile-map' />
            </Link>
          </div>
        </div>
      )}
    </>
  )
}

export default InteractiveMap
