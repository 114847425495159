import React, { useState, useEffect } from 'react'
import { Header, Footer } from '../../containers'
import { Avatar, Profile } from '../../components'
import { useLocation } from 'react-router-dom'
import creatorsData from '../../data/creators.json'
import './creatorsPage.css'

const CreatorsPage = () => {
  const [selectedCreator, setSelectedCreator] = useState(false)

  const creatorsDataArray = Array.from(creatorsData)

  const handleMemberClick = () => {
    // const creator = creatorsDataArray.find((creator) => creator.id === id)
    // console.dir(id)
    // console.log(creator)
    // selectedCreator === creator ? setSelectedCreator(null) : setSelectedCreator(creator)
    // console.log(selectedCreator)
    setSelectedCreator(!selectedCreator)
  }

  console.log(creatorsDataArray[0].arts)

  const location = useLocation()
  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [location])

  return (
    <div className='App '>
      <Header title='Художники' />
      <div className='creators__container'>
        {/* <Profile /> */}
        {/* {selectedCreator && (
          <Profile bio={selectedCreator.bio} key={member.id}>
            <Avatar name={selectedCreator.name} role={selectedCreator.role} avatarUrl={selectedCreator.avatarUrl} />
          </Profile>
        )} */}
        {creatorsDataArray.map((member) => (
          <div
            key={member.id}
            className='member__grid'
            // onClick={(e) => {
            //   handleMemberClick()
            // }}
          >
            <Profile bio={member.bio} id={member.id} name={member.name} role={member.role} avatarUrl={member.avatarUrl} selectedCreator={selectedCreator} arts={member.arts} />
          </div>
          //   <div
          //   key={member.id}
          //   onClick={() => {
          //     handleMemberClick(member.id)
          //   }}
          // >
          //   <Avatar name={member.name} role={member.role} avatarUrl={member.avatarUrl} key={member.id} />
          //   {selectedCreator && }
          // </div>
        ))}
      </div>
      <Footer />
    </div>
  )
}

export default CreatorsPage
