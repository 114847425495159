import React from 'react'
import './footer.css'
import vk from '../../assets/vk.svg'
import telegram from '../../assets/tg.svg'
import youtube from '../../assets/yb.svg'

const Footer = () => {
  return (
    <>
      <div className='temp__badge section__padding'>
        <p className='temp__badge-text'>
          Проект реализуется при поддержке
          <br />
          Фонда грантов губернатора
          <br />
          Пермского края
        </p>
        <img src='./images/badge_logo.svg' alt='' />
      </div>
      <div className='footer section__padding'>
        <div className='footer-m'>
          <a href='mailto: graffiti.perm@yandex.ru' target='_blank' rel='noopener noreferrer' className='footer__copy-links'>
            Поддержка
          </a>
          <a href='https://disk.yandex.ru/i/HfLDW_vslz5V7g' target='_blank' rel='noopener noreferrer' className='footer__copy-links'>
            Monument Art
          </a>
        </div>

        <div className='footer__socials'>
          <a href='https://vk.com/public219627176' target='_blank' rel='noopener noreferrer'>
            <img src={vk} alt='vk' />
          </a>
          <a href='https://t.me/+2xqi2zCWfUY4YmY0' target='_blank' rel='noopener noreferrer'>
            <img src={telegram} alt='telegram' />
          </a>
          {/* <a href='/'>
          <img src={youtube} alt='youtube' />
        </a> */}
        </div>
        <div className='footer__copy-container'>
          <div className='footer__copy'>
            <p className='footer__copy-text'>Не является публичной офертой</p>
            <a href='/' target='_blank' rel='noopener noreferrer' className='footer__copy-text footer__copy-links'>
              Политика конфиденциальности
            </a>
            <p className='footer__copy-text mobile-v'>Дизайн и разработка сайта ИП&nbsp;Шараборина&nbsp;Е.В,&nbsp;2023 </p>
            <p className='footer__copy-text'>© 2025 Граффити Пермь</p>
          </div>
          <p className='footer__copy-text desktop-v'>Дизайн и разработка сайта ИП&nbsp;Шараборина&nbsp;Е.В </p>
        </div>
      </div>
    </>
  )
}

export default Footer
